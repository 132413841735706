import type { PharmacyDetailsResponse } from "@9amhealth/openapi";
import { NineInput } from "@9amhealth/wcl";
import type { NineSubmitEvent } from "@9amhealth/wcl/generated/events/events";
import {
  NineActions,
  NineButton,
  NineHeading
} from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import { IonButton, IonModal, IonSearchbar } from "@ionic/react";
import clsx from "clsx";
import type { FC } from "react";
import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  IconChevronRight,
  IconCloseCircle,
  IconCrossXCloseMissed,
  IconFax,
  IconFilterHorizontalSliders,
  IconMap,
  IconPharmacyMedicalCupSnakeBowlOfHygieia,
  IconPhone
} from "src/constants/icons";
import { APP_BREAKPOINT } from "src/constants/layout";
import { formatPhoneNumberNational } from "src/lib/formatPhoneNumber";
import { formatFiveDigitZipCode } from "src/lib/formatZipCode";
import reportErrorSentry from "src/lib/reportErrorSentry";
import toTitleCase from "src/lib/toTitleCase";
import translate from "src/lib/translate";
import type { PharmacySearchFormValues } from "src/state/PatientPharmacyBloc/PatientPharmacyBloc";
import PatientPharmacyBloc from "src/state/PatientPharmacyBloc/PatientPharmacyBloc";
import { useBloc } from "src/state/state";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import { PROFILE_PHARMACY_INFO_ROUTE } from "src/ui/components/PharmacyInformationDetail/PharmacyInformationDetail";
import Translate from "src/ui/components/Translate/Translate";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";

//#region Styled Components
export const SearchResultWrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  gap: 0.625rem;
  margin-bottom: 0.625rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &.active {
    background: var(--secondary-dark-cream, #f2efe7);
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin-bottom: 1rem;

    &:hover {
      background: var(--secondary-dark-cream, #f2efe7);
    }
  }
`;

export const ResultsNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin-bottom: 1rem;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;
  padding: 0.25rem 0.375rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &.active {
    background: var(--secondary-dark-cream, #f2efe7);
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    &:hover {
      background: var(--secondary-dark-cream, #f2efe7);
    }
  }
`;

export const FilterInputWrapper = styled.div`
  margin-bottom: 0.625rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin-bottom: 1rem;
  }
`;

export const HelpTextWrapper = styled.div`
  padding: 0.5rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 0.5rem;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

export const ClearButton = styled.button`
  background: none;
  height: 1.5rem;
  margin: auto 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;

    path {
      fill-opacity: 1;
    }
  }
`;
//#endregion

export enum AddPharmacyInfoAction {
  SELECT_PHARMACY = "select-pharmacy"
}

const PharmacyForm: FC<{ onPharmacySaved?: () => void }> = ({
  onPharmacySaved
}) => {
  const [
    { loading, preferredPharmacy, pharmacySearchResults },
    {
      temporaryPharmacySearchFormValues,
      searchPharmacies,
      savePreferredPharmacy,
      clearPharmacySearchResults
    }
  ] = useBloc(PatientPharmacyBloc);
  const [filterInputVisible, setFilterInputVisible] = useState(false);
  const [searchButtonVisible, setSearchButtonVisible] = useState(true);
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(true);
  const [forceZipError, setForceZipError] = useState("");
  const [filteredPharmacySearchResults, setFilteredPharmacySearchResults] =
    useState<PharmacyDetailsResponse[]>();
  const [selectedPharmacy, setSelectedPharmacy] =
    useState<PharmacyDetailsResponse>();
  const [selectedPharmacyIndex, setSelectedPharmacyIndex] = useState<number>();

  const inputRef = useRef<NineInput>(null);
  const pharmacyInfoModalRef = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    if (preferredPharmacy?.zipCode) {
      temporaryPharmacySearchFormValues.current = {
        zip: formatFiveDigitZipCode(preferredPharmacy.zipCode)
      };
      void searchPharmacies(temporaryPharmacySearchFormValues.current);
      setSearchButtonVisible(false);
    }
  }, []);

  const zipCodeValue = useMemo(() => {
    if (preferredPharmacy?.zipCode) {
      setSearchButtonDisabled(false);
      return preferredPharmacy.zipCode;
    }

    return temporaryPharmacySearchFormValues.current?.zip ?? "";
  }, [preferredPharmacy]);

  const selectedPharmacyPhoneNumber = useMemo(
    () => formatPhoneNumberNational(selectedPharmacy?.primaryPhone, false),
    [selectedPharmacy]
  );

  const selectedPharmacyFaxNumber = useMemo(
    () => formatPhoneNumberNational(selectedPharmacy?.primaryFax, false),
    [selectedPharmacy]
  );

  const dismissPharmacyInfoModal = () => {
    void pharmacyInfoModalRef.current?.dismiss();
  };

  const navigate = useGoToOrBack();
  const goto =
    (
      goToAction: AddPharmacyInfoAction | "",
      replace?: boolean,
      useBack = true
    ) =>
    () => {
      dismissPharmacyInfoModal();
      navigate(`${PROFILE_PHARMACY_INFO_ROUTE}/${goToAction}`, {
        useBack,
        replace,
        multiBack: replace
      });
    };

  const handleSearchPharmacies = (
    e: NineSubmitEvent<PharmacySearchFormValues>
  ) => {
    const input = inputRef.current;

    if (!input?.maskFilled) {
      setSearchButtonDisabled(true);
      setForceZipError(translate("invalid_zip_code"));
      return;
    }

    temporaryPharmacySearchFormValues.current = e.detail;
    void searchPharmacies(temporaryPharmacySearchFormValues.current);
    setSearchButtonVisible(false);
    input.querySelector("input")?.blur();
  };

  const handleInputChange = () => {
    setForceZipError("");
    setSearchButtonDisabled(false);
    setSearchButtonVisible(true);
    setFilteredPharmacySearchResults(undefined);
    clearPharmacySearchResults();
  };

  const parsePharmacyAddress = (pharmacy: PharmacyDetailsResponse) => {
    const addressString = `${pharmacy.address1}${
      pharmacy.address2 ? `, ${pharmacy.address2}` : ""
    }, ${pharmacy.city}`;

    return `${toTitleCase(addressString)}, ${pharmacy.state} ${
      pharmacy.zipCode
    }, USA`;
  };

  const displaySearchResultsNumber = () => {
    if (!pharmacySearchResults) {
      return "";
    }

    const results = filteredPharmacySearchResults ?? pharmacySearchResults;
    const resultsLength = results.length;
    const resultsLengthString =
      resultsLength > 100 ? "100+" : resultsLength.toString() + "";
    const plural = resultsLength !== 1;

    return `${resultsLengthString} result${plural ? "s" : ""} found`;
  };

  const handleFilterButtonClick = () => {
    setFilteredPharmacySearchResults(undefined);
    setFilterInputVisible(!filterInputVisible);
  };

  const handleSearchResultsFilter = (e: Event) => {
    const filterValue = (e.target as HTMLInputElement).value.toLowerCase();

    if (!filterValue.length) {
      setFilteredPharmacySearchResults(undefined);
      return;
    }

    const filteredResults = pharmacySearchResults?.filter((pharmacy) =>
      [
        pharmacy.pharmacyName,
        pharmacy.address1,
        pharmacy.address2,
        pharmacy.city
      ]
        .join(", ")
        .toLowerCase()
        .includes(filterValue)
    );

    setFilteredPharmacySearchResults(filteredResults);
  };

  const handlePharmacySearchResultClick = (
    pharmacy: PharmacyDetailsResponse,
    i: number
  ) => {
    setSelectedPharmacy(pharmacy);
    setSelectedPharmacyIndex(i);
  };

  const handlePharmacyConfirmationModalDismiss = () => {
    setSelectedPharmacy(undefined);
    setSelectedPharmacyIndex(undefined);
  };

  const handleConfirmPharmacySelection = () => {
    if (!selectedPharmacy) {
      return;
    }

    BlockingLoadingOverlayController.startLoading({
      bg: "transparent"
    });

    void savePreferredPharmacy(selectedPharmacy)
      .then(() => {
        BlockingLoadingOverlayController.loadingSuccess();
        onPharmacySaved?.();
        temporaryPharmacySearchFormValues.current = null;
      })
      .catch((e: unknown) => {
        reportErrorSentry(e);
        BlockingLoadingOverlayController.loadingError({
          retry: () => {
            handleConfirmPharmacySelection();
          },
          onCancel: () => {
            goto("", true, true)();
          }
        });
      });
  };

  // TODO: Fix the logic of clearing the input - focus, blur, focus should be replaced
  const clearInput = () => {
    const inputHTMLElement = inputRef.current?.querySelector("input");

    if (inputHTMLElement) {
      inputRef.current?.setValue("");
      // re-focus input html element in order for mask to work properly
      inputHTMLElement.focus();
      inputHTMLElement.blur();
      inputHTMLElement.focus();
    }
  };

  return (
    <div style={{ padding: "0 0.6rem" }}>
      <NineHeading>
        <h3 className="as-h4-large">
          <Translate msg="pharmacyStore.select" />
        </h3>
        <nine-spacer s="xs"></nine-spacer>
      </NineHeading>
      <nine-spacer s="lg"></nine-spacer>

      <OnEvent
        events={{
          submit: handleSearchPharmacies,
          [NineInput.customEvents.change]: handleInputChange
        }}
      >
        <nine-form>
          <nine-input
            name="zip"
            label="ZIP code"
            required="true"
            mask="00000"
            type="tel"
            force-error={forceZipError}
            value={zipCodeValue}
            ref={inputRef}
          >
            <ClearButton slot="decoration-end" onClick={clearInput}>
              <IconCrossXCloseMissed />
            </ClearButton>
          </nine-input>

          {searchButtonVisible && (
            <NineActions style={{ justifyContent: "center" }}>
              <NineButton disabled={`${searchButtonDisabled}`} type="submit">
                <Translate msg="search" />
              </NineButton>
            </NineActions>
          )}
        </nine-form>
      </OnEvent>
      <nine-spacer s="sm"></nine-spacer>

      <AsyncContent height="20rem" check={[loading === false]}>
        {pharmacySearchResults && (
          <>
            <ResultsNumberWrapper>
              <small className="m0 strong">
                {displaySearchResultsNumber()}
              </small>
              <FilterWrapper
                onClick={handleFilterButtonClick}
                className={clsx({
                  active: filterInputVisible
                })}
              >
                <IconFilterHorizontalSliders />
                <small className="m0 strong">
                  <Translate msg="filter" />
                </small>
              </FilterWrapper>
            </ResultsNumberWrapper>

            {filterInputVisible && (
              <FilterInputWrapper>
                <IonSearchbar
                  autocapitalize="off"
                  className="filter-search-results"
                  placeholder={translate("filter.address.help.short")}
                  onIonInput={handleSearchResultsFilter}
                ></IonSearchbar>
              </FilterInputWrapper>
            )}

            {(filteredPharmacySearchResults ?? pharmacySearchResults).map(
              (pharmacy, i) => (
                <SearchResultWrapper
                  className={clsx({
                    active: i === selectedPharmacyIndex
                  })}
                  key={`${pharmacy.pharmacyName} - ${i}`}
                  onClick={() => handlePharmacySearchResultClick(pharmacy, i)}
                >
                  <IconPharmacyMedicalCupSnakeBowlOfHygieia />

                  <div style={{ width: "100%" }}>
                    <p className="m0 strong">{pharmacy.pharmacyName}</p>
                    <nine-spacer s="xxxs"></nine-spacer>
                    <small className="color-c-80">
                      {parsePharmacyAddress(pharmacy)}
                    </small>
                  </div>

                  <div style={{ alignSelf: "center" }}>
                    <IconChevronRight />
                  </div>
                </SearchResultWrapper>
              )
            )}

            <HelpTextWrapper>
              <div className="m0 lauf-text">
                <p className="m0 strong">
                  <Translate msg="pharmacy.notFound.question" />
                </p>
                <nine-spacer s="xs"></nine-spacer>
                <small>
                  <Translate msg="pharmacy.notFound.help" />
                </small>
                <nine-spacer s="xs"></nine-spacer>
                <ul className="m0">
                  <li className="m0">
                    <small>
                      <Translate msg="name" />
                    </small>
                  </li>
                  <li className="m0">
                    <small>
                      <Translate msg="fullAddress" />
                    </small>
                  </li>
                  <li className="m0">
                    <small>
                      <Translate msg="phoneNumber" />
                    </small>
                  </li>
                  <li className="m0">
                    <small>
                      {" "}
                      <Translate msg="faxNumber" />
                    </small>
                  </li>
                  <li className="m0">
                    <small>
                      <Translate msg="email" />
                    </small>
                  </li>
                </ul>
              </div>
            </HelpTextWrapper>
          </>
        )}
      </AsyncContent>

      <IonModal
        isOpen={Boolean(selectedPharmacy)}
        onDidDismiss={handlePharmacyConfirmationModalDismiss}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.25, 0.5, 0.75]}
        className="selected-search-result-info"
        ref={pharmacyInfoModalRef}
      >
        <div>
          <IonButton
            aria-label="Close"
            className="close-modal-button"
            onClick={dismissPharmacyInfoModal}
          >
            <IconCloseCircle />
          </IonButton>

          <nine-spacer s="md"></nine-spacer>
          <p className="m0 strong as-subhead">
            {selectedPharmacy?.pharmacyName}
          </p>

          <nine-spacer s="sm"></nine-spacer>
          {selectedPharmacy && (
            <InfoWrapper>
              <div style={{ display: "flex" }}>
                <IconMap />
              </div>
              <p className="m0 color-c-80">
                {parsePharmacyAddress(selectedPharmacy)}
              </p>
            </InfoWrapper>
          )}

          {selectedPharmacyPhoneNumber && (
            <InfoWrapper>
              <div style={{ display: "flex" }}>
                <IconPhone />
              </div>
              <p className="m0 color-c-80">
                <a href={`tel:${selectedPharmacy?.primaryPhone}`}>
                  {selectedPharmacyPhoneNumber}
                </a>
              </p>
            </InfoWrapper>
          )}

          {selectedPharmacyFaxNumber && (
            <InfoWrapper>
              <div style={{ display: "flex" }}>
                <IconFax />
              </div>
              <p className="m0 color-c-80">{selectedPharmacyFaxNumber}</p>
            </InfoWrapper>
          )}

          <nine-spacer s="md"></nine-spacer>
          <NineActions style={{ justifyContent: "center" }}>
            <NineButton onClick={handleConfirmPharmacySelection}>
              <Translate msg="confirm" />
            </NineButton>
          </NineActions>
        </div>
      </IonModal>
    </div>
  );
};

export default PharmacyForm;
